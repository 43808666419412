import React from "react";
import Button from "../elements/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import SavingsIcon from "@mui/icons-material/Savings";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CoffeeIcon from "@mui/icons-material/Coffee";

export default function SuccessDialog({ open, handleClose, drawDate }) {
  return (
    <Dialog
      size="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        className="flex !text-base !font-bold items-center text-primary uppercase"
      >
        <CheckCircleIcon className="text-green" />
        &nbsp;Прогнози збережено
      </DialogTitle>
      <DialogContent>
        <p className="mb-4 leading-snug">
          Прогнози на тур збережено! Ваш суперник по батлу визначиться{" "}
          <strong className="whitespace-nowrap">{drawDate} року</strong>
        </p>
        <p className="mb-4 leading-snug">
          Ви можете змінювати ваші прогнози до запланованого часу початку
          футбольного матчу.
        </p>
        <p className="mb-4 leading-snug">
          Для того щоб бути в курсі всіх новин ліги та отримувати оповіщення про
          наступні раунди підпишіться на{" "}
          <a
            rel="noreferrer noopener"
            href="https://t.me/+r7cW6SfXBpJlYjIy"
            target="_blank"
          >
            телеграм канал ФУТБАТЛ
          </a>
        </p>
      </DialogContent>
      <div className="bg-gold px-6 py-2">
        <h4 className="font-bold uppercase mb-1">Підтримати проєкт:</h4>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
          <div>
            <a
              className="no-underline uppercase text-black hover:text-primary"
              href="https://send.monobank.ua/jar/4LjmaBfmXY"
              target="_blank"
              rel="noreferrer"
            >
              <SavingsIcon />
              &nbsp;Монобанка
            </a>
          </div>
          <div>
            <CreditCardIcon />
            &nbsp;5375411210204470
          </div>
          <div>
            <a
              className="no-underline uppercase text-black hover:text-primary"
              href="https://www.buymeacoffee.com/footbattle"
              target="_blank"
              rel="noreferrer"
            >
              <CoffeeIcon />
              &nbsp;Купіть мені каву
            </a>
          </div>
        </div>
      </div>

      <DialogActions className="bg-primary !flex !justify-center !h-16">
        <Button variant="outlined" onClick={handleClose}>
          <CheckIcon />
          &nbsp;ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
}
