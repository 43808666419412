import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { primaryRedTransparent, gray } from '../constants'
const styles = {
    player: {
        position: 'relative',
        border: `1px solid ${gray}`,
        overflow: 'hidden',
        cursor: 'pointer'
    },
    img: {
        width: '100%',
        display: 'flex',
        height: 90,
        objectFit: 'cover'
    },
    playerNo: {
        backgroundColor: primaryRedTransparent,
        color: '#fff',
        padding: '0 4px',
        fontSize: '1rem',
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold'
    },
    hearts: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: primaryRedTransparent,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 2,
        width: 34
    }
}

const PlayerCard = ({ logoUrl, playerNo, active, hearts, onClick}) => {
    return (
        <div onClick={onClick} style={{...styles.player, filter: active ? 'none' : 'brightness(15%)'}}>
            <img 
                style={styles.img}
                src={logoUrl} 
                alt={playerNo}
                className="avatar" />
            <div style={styles.playerNo}>{playerNo}</div>
            {active && <div style={styles.hearts}>
                <FavoriteIcon style={{ fontSize: '1rem' }} />
                {hearts}
            </div> }
        </div>
    )
}

export default PlayerCard