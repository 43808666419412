import axios from "axios";

export const baseUrl =
  "https://14f4lbfb5e.execute-api.us-east-1.amazonaws.com/default";
export const baseAuthUrl = "https://www.patreon.com";

const getAuthorization = () => `bearer ${localStorage.getItem("access_token")}`;

const API = {
  fetchTeams: () => {
    return axios.get(`${baseUrl}/teams`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchUserInfo: (code) => {
    const queryString = code
      ? `?code=${code}&redirect_uri=${encodeURIComponent(
          window.location.origin
        )}/login`
      : "";
    return axios.get(`${baseUrl}/auth${queryString}`, {
      headers: {
        Authorization: code
          ? ""
          : `bearer ${localStorage.getItem("access_token")}`,
      },
    });
  },
  fetchMatches: () => {
    return axios.get(`${baseUrl}/matches`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  newRound: () => {
    return axios.post(`${baseUrl}/matches`, null, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchPlayers: () => {
    return axios.get(`${baseUrl}/users`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchPredictions: () => {
    const config = {
      headers: {
        Authorization: getAuthorization(),
      },
    };
    return axios.get(`${baseUrl}/predictions`, config);
  },
  fetchPublicPredictions: (user) => {
    return axios.get(`${baseUrl}/results?userId=${user}`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchDuels: ({ userId, round }) => {
    let queryString = ``;

    if (userId) {
      queryString = `?playerId=${userId}`;
    } else if (round) {
      queryString = `?round=${round}`;
    }

    return axios.get(`${baseUrl}/duels${queryString}`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchDuel: (duelId) => {
    return axios.get(`${baseUrl}/duel?duelId=${duelId}`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  calculate: () => {
    return axios.post(
      `${baseUrl}/calculate`,
      {},
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  closeRound: () => {
    return axios.put(
      `${baseUrl}/calculate`,
      {},
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  savePrediction: (matchId, firstScore, secondScore, id) => {
    const config = {
      headers: {
        Authorization: getAuthorization(),
      },
    };
    return axios.post(
      `${baseUrl}/predictions`,
      {
        matchId,
        firstScore,
        secondScore,
        id,
      },
      config
    );
  },
  register: (body) => {
    const config = {
      headers: {
        Authorization: getAuthorization(),
      },
    };
    return axios.post(`${baseUrl}/auth`, body, config);
  },
  saveMatch: (body) => {
    const config = {
      headers: {
        Authorization: getAuthorization(),
      },
    };
    return axios.put(`${baseUrl}/matches`, body, config);
  },
};

export default API;
