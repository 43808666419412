import React, { useState } from "react";
import Page from "../components/Page";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "../elements/Button";
import { connect } from "react-redux";
import { register } from "../actions/user";
import { fetchPlayers } from "../actions/init";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Loader from "../components/Loader";

const Registration = ({ register, fetchPlayers, isRegistrationActive }) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const goToHome = () => {
    navigate("/home");
  };

  const onChange = (event, value) => {
    setChecked(value);
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const onClick = async () => {
    try {
      setLoading(true);
      await register({
        agreement: checked,
      });
      await fetchPlayers();
      goToHome();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  return loading ? (
    <Page>
      <div className="h-[calc(100vh-65px)] bg-white flex justify-center items-center">
        <Loader />
      </div>
    </Page>
  ) : (
    <Page>
      <Grid item xs={12}>
        <div className="bg-primary flex flex-col relative">
          <div className="relative">
            <h2 className="uppercase text-2xl font-bold text-gold bg-gradient-to-t from-primary/80 to-primaryLight/80 p-0 border-none absolute top-0 left:0 flex h-full w-full text-center items-center justify-center m-0">
              Прийміть умови гри та натисніть кнопку "Зареєструватися"
            </h2>
            <img
              className="w-full h-[340px] md:h-[440px] object-cover"
              src="/hero.jpg"
              alt="Футбольна гра"
            />
          </div>
          {isRegistrationActive !== false ? (
            <>
              <div className="bg-white p-4">
                <h3 className="text-primary m-0 uppercase font-bold mb-4">
                  Реєстрація у грі
                </h3>
                <p className="text-black mb-4">
                  Будь ласка, уважно ознайомтеся з політикою конфіденційності та
                  правилами і умовами користування веб-додатком{" "}
                  <a target="_blank" href="/FB_Terms_and_Conditions.pdf">
                    тут
                  </a>
                  .
                </p>

                <p className="text-black mb-4">
                  Для участі у грі вам потрібно зареєструватися та вчасно робити
                  прогнози на матчі української прем'єр ліги.
                </p>

                <p className="text-black mb-4">
                  Прогнози на матчі приймаються через веб-застосунок. Ви можете
                  надсилати та змінювати їх в будь-який час, але до
                  запланованого часу початку поєдинку.
                </p>

                <p className="text-black mb-4">
                  З метою швидкого зв'язку між вами та організаторами гри,
                  рекомендуємо Вам підписатися на:
                  <ul className="mt-4">
                    <li>
                      <a
                        href="https://t.me/+r7cW6SfXBpJlYjIy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Tелеграм канал "Футбатл"
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/footbattle.click"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Інстаграм "Футбатл"
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/groups/footbattle"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Сторінку гри у Facebook
                      </a>
                    </li>
                  </ul>
                </p>
                <FormGroup>
                  <FormControlLabel
                    className="text-primary"
                    control={<Checkbox onChange={onChange} value={checked} />}
                    label="Я погоджуюсь з умовами гри"
                  />
                </FormGroup>
              </div>
              <div className="flex items-center justify-center p-4 flex-col bg-primary">
                <Button onClick={onClick} disabled={!checked || loading}>
                  <HowToRegIcon />
                  &nbsp;Зареєструватися
                </Button>
              </div>
            </>
          ) : (
            <div className="bg-white p-4">
              <h3 className="text-primary m-0 uppercase font-bold mb-4">
                Реєстрацію завершено
              </h3>
            </div>
          )}
        </div>
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isRegistrationActive: state.user.isRegistrationActive,
});

const mapDispatchToProps = (dispatch) => ({
  register: (body) => dispatch(register(body)),
  fetchPlayers: () => dispatch(fetchPlayers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
