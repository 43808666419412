import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PredictionCard from "./PredictionCard";
import { getTeamName } from "../utils";
import { Skeleton, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Head from "../components/Head";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import SuccessDialog from "../components/SuccessDialog";
import dayjs from "dayjs";

const getInitialScore = (matchId, myPredictions) => {
  if (!matchId || !myPredictions.length) return;
  const targetRec =
    myPredictions.find((myPred) => myPred.MatchId === matchId) || {};
  return `${targetRec.FirstScore}:${targetRec.SecondScore}`;
};

const getPredictionId = (matchId, myPredictions) => {
  if (!matchId || !myPredictions.length) return;
  const targetPrediction = myPredictions.find(
    (myPred) => myPred.MatchId === matchId
  );
  return targetPrediction ? targetPrediction.PredictionId : "";
};

const isActiveDeadline = (match) => {
  return new Date().getTime() <= new Date(match.Deadline).getTime();
};

const isActiveRoundDeadline = (matches) => {
  return new Date().getTime() <= new Date(matches[0]?.Deadline).getTime();
};

const getPts = (matchId, myPredictions) => {
  if (!matchId || !myPredictions.length) return;
  const targetPrediction = myPredictions.find(
    (myPred) => myPred.MatchId === matchId
  );
  return targetPrediction ? targetPrediction.Pts : "";
};

const isLive = (match) => {
  return !isActiveDeadline(match) && !match.FirstScore && !match.SecondScore;
};

const getNextMatchIndex = (matches) => {
  const matchesList = [...matches];
  const index = matchesList?.reverse().findIndex((match) => match.Calculated);
  return index === -1 ? 0 : matchesList.length - index;
};

export const Placeholder = ({ meActive, loaded }) => {
  if (loaded && !meActive) return null;
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <Grid key={item} item xs={6}>
          <Skeleton
            className="!bg-lightgrey"
            animation="wave"
            variant="rectangular"
            width="100%"
            height={162}
          />
        </Grid>
      ))}
    </>
  );
};

const MyPredictions = ({
  matches,
  teams,
  myPredictions,
  loaded,
  meActive,
  hasDuel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // eslint-disable-next-line
  const [savedMatchIds, setSavedMatchIds] = useState(new Set([]));
  const [successDialog, setSuccessDialog] = useState(false);

  useEffect(() => {
    if (loaded && matches?.length > 0) {
      const nextMatchIndex = getNextMatchIndex(matches) - 2;
      const scrollOffset = Math.round(nextMatchIndex / 2) * 172;
      setTimeout(
        () =>
          window.scrollTo({
            top: scrollOffset,
            behavior: "smooth",
          }),
        200
      );
    }
  }, [isMobile, matches, loaded]);

  const successMessageCheck = (savedMatchIds, myPredictions) => {
    const initialPredictions = myPredictions.filter(
      (pred) => pred.FirstScore !== "" && pred.SecondScore !== ""
    );

    if (
      !window.successDialogWasShown &&
      savedMatchIds.size > initialPredictions.length &&
      savedMatchIds.size === myPredictions.length
    ) {
      window.successDialogWasShown = true;
      setSuccessDialog(true);
    }
  };

  const onSaveMatchId = (matchId) => {
    setSavedMatchIds((prevMatchIds) => {
      const savedIds = myPredictions
        .filter((pred) => pred.FirstScore !== "" && pred.SecondScore !== "")
        .map((entry) => entry.MatchId);

      const newSavedMatchIds = new Set([...prevMatchIds, ...savedIds, matchId]);
      successMessageCheck(newSavedMatchIds, myPredictions);
      return newSavedMatchIds;
    });
  };

  const isNotActiveRoundDeadline = !isActiveRoundDeadline(matches);
  const isShowExplanation =
    isNotActiveRoundDeadline &&
    myPredictions.filter(
      (pred) => pred.FirstScore !== "" && pred.SecondScore !== ""
    ).length === 0;

  return loaded && isShowExplanation ? (
    <div className={`pb-2 bg-primary pt-[8.5rem]`}>
      <Grid
        container
        spacing={0}
        marginTop={-0.5}
        paddingLeft={0}
        paddingRight={0}
      >
        <Head icon={<TimerOffIcon />} text="Ви не встигли надіслати прогнози" />
      </Grid>
    </div>
  ) : (
    <div className={`pt-[8.5rem] pb-2 bg-primary`}>
      <Grid
        container
        spacing={1}
        marginTop={-0.5}
        paddingLeft={1}
        paddingRight={1}
      >
        {loaded && meActive ? (
          <>
            {matches.map((match, index) => {
              let isHead = false;
              if (
                match.IsAdditional !==
                matches[index > 0 ? index - 1 : 0].IsAdditional
              ) {
                isHead = true;
              }

              return (
                <React.Fragment key={index}>
                  {isHead && <Head text="Додаткові матчі" />}
                  <PredictionCard
                    key={index}
                    index={index + 1}
                    firstTeam={match.FirstTeam}
                    firstTeamName={getTeamName(
                      match.FirstTeam,
                      teams,
                      isMobile
                    )}
                    secondTeam={match.SecondTeam}
                    secondTeamName={getTeamName(
                      match.SecondTeam,
                      teams,
                      isMobile
                    )}
                    deadline={match.Deadline}
                    match={match}
                    firstScore={match.FirstScore}
                    secondScore={match.SecondScore}
                    initialScore={getInitialScore(match.MatchId, myPredictions)}
                    id={getPredictionId(match.MatchId, myPredictions)}
                    isActiveDeadline={isActiveDeadline(match)}
                    live={isLive(match)}
                    pts={getPts(match.MatchId, myPredictions)}
                    loaded={loaded}
                    onSaveMatchId={onSaveMatchId}
                  />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <Placeholder meActive={meActive} loaded={loaded} />
        )}
      </Grid>
      <SuccessDialog
        open={successDialog}
        handleClose={() => setSuccessDialog(false)}
        drawDate={dayjs(new Date(matches?.[0]?.Deadline))
          .locale("uk")
          .format("DD MMM YYYY")}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  matches: state.matches,
  teams: state.teams,
  myPredictions: state.myPredictions,
});

const mapDispatchToProps = (dispatch) => ({
  /* fetchApplicationData: (navigate) => dispatch(fetchApplicationData(navigate)), */
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPredictions);
