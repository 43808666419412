import React from "react";
import Page from "../components/Page";
import Loader from "../components/Loader";
import { useSearchParams } from "react-router-dom";

const styles = {
  container: {
    height: "calc(100vh - 65px)",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const MobileAuth = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  if (window.Auth) {
    window.Auth.postMessage(code);
  }

  return (
    <Page>
      <div style={styles.container}>
        <Loader />
      </div>
    </Page>
  );
};

export default MobileAuth;
