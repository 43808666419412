import React from 'react';
import Page from '../components/Page';
import Navigation from '../components/Navigation';
import Grid from '@mui/material/Grid';
import { primaryRed, primaryBlue } from '../constants';
import FavoriteIcon from '@mui/icons-material/Favorite';

const styles = {
    card: {
        backgroundColor: primaryRed,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    dollImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top',
        position: 'relative',
        top: 0
    },
    bar: {
        backgroundColor: '#fff',
        padding: 16,
    },
    head: {
        margin: 0,
        color: primaryRed,
        textTransform: 'uppercase'
    },
    paragraph: {
        color: primaryBlue,
    },
    panel: {
        position: 'absolute',
        left: 0,
        top: 24,
        height: '100%',
        width: '100%',
    },
    gamePanel: {
        position: 'absolute',
        backgroundColor: primaryRed,
        top: 0,
        padding: '0 16px',
        textTransform: 'uppercase'
    },
    hint: {
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        marginLeft: 8,
        marginBottom: 8
    },
    imgContainer: {
        position: 'relative',
    },
    traficLights: {
        position: 'absolute',
        height: 100,
        right: 12,
        top: 12
    },
    hearts: {        
        color: primaryRed,
        fontSize: '1rem',
        position: 'relative',
        top: 2
    }
}

const FirstGame = () => {

    return (
        <Page>
            <Navigation title="Правила гри" />
            <Grid item xs={12}>
                <div style={styles.card}>
                    <div style={{...styles.imgContainer, height: 260, marginTop: 41}}>
                        <img src="/doll.webp" alt="Червоне світло - зелене світл" style={styles.dollImage} />
                        <div style={styles.gamePanel}>
                            <h3 style={styles.question}>Гра 1</h3>
                        </div>
                        <img style={styles.traficLights} src="/traffic-light-icon.png" alt="traffic lights" />  
                    </div>
                    
                    <div style={styles.bar}>
                        <h3 style={styles.head}>Червоне світло - зелене світло</h3>
                        <p style={styles.paragraph}>
                            "Червоне світло - зелене світло" - це основна гра, що триватиме під час 1/8 фіналу Ліги чемпіонів (16 матчів).
                        </p>
                        <p style={styles.paragraph}>
                            Кожний зареєстрований гравець отримує на свій баланс <strong>8</strong><FavoriteIcon style={styles.hearts} /> та відправляє прогнози на рахунки матчів 1/8 фіналу Ліги чемпіонів. 
                        </p>
                        <p style={styles.paragraph}>   
                            Кожен гравець може змінювати свою ставку аж до самого початку кожного конкретного матчу.
                        </p>
                        <p style={styles.paragraph}>
                            <ul>
                                <li>Якщо гравець вгадує точний рахунок матчу (зелене світло) - отримує <strong>+1</strong><FavoriteIcon style={styles.hearts} /></li>
                                <li>Якщо гравець вгадує результат матчу: перемога, нічия, поразка (жовте світло) - баланс сердечок не змінюється</li>
                                <li>Якщо матч спрогнозовано не вірно (червоне світло) - гравець втрачає <strong>-1</strong><FavoriteIcon style={styles.hearts} /></li>
                                <li>Якщо зареєстрований гравець не надішле прогноз на матч, він втрачає <strong>-1</strong><FavoriteIcon style={styles.hearts} /></li>
                            </ul>
                        </p>
                        <p style={styles.paragraph}>
                            
                        </p>
                        <p style={styles.paragraph}>
                            <strong>Мета гри:</strong> прийти до фінішу з позитивним балансом <FavoriteIcon style={styles.hearts} /> (більше 0).
                            Як тільки баланс <FavoriteIcon style={styles.hearts} /> гравця дорівнює 0 - гравець гру залишає. 
                        </p>
                    </div>
                </div>
            </Grid>
        </Page>
    )
}

export default FirstGame;