import React from "react";
import dayjs from "dayjs";
import CheckCircleSharp from "@mui/icons-material/CheckCircleSharp";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CircularProgress from "@mui/material/CircularProgress";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Live from "./Live";

const getMatchOutcome = (chunks) => {
  const firstScore = Number(chunks[0]);
  const secondScore = Number(chunks[1]);

  if (firstScore > secondScore) {
    return 1;
  } else if (firstScore === secondScore) {
    return 0;
  } else {
    return 2;
  }
};

const getResult = (value, finalScore) => {
  const valueChunks = value.split(":");
  const finalScoreChunks = finalScore.split(":");

  const valueOutcome = getMatchOutcome(valueChunks);
  const actualOutcome = getMatchOutcome(finalScoreChunks);

  if (valueOutcome === actualOutcome) {
    return (
      <>
        <div className="flex justify-center relative text-2xl top-0 text-primary">
          <span className="text-primary relative h-12 flex items-center justify-center">
            <ThumbUpIcon className="!text-4xl" />
          </span>
        </div>
        <div className="relative text-xs font-bold top-1.5"></div>
      </>
    );
  } else {
    return (
      <>
        <div className="relative text-2xl text-primary flex justify-center top-0">
          <span className="text-primary relative h-12 flex items-center justify-center">
            <ThumbDownIcon className="!text-4xl" />
          </span>
        </div>
        <div className="relative text-xs font-bold top-1.5"></div>
      </>
    );
  }
};

const getProbe = (pts) => {
  let probe = <></>;
  if (pts === undefined) {
    probe = (
      <div className="flex w-7 h-7 bg-darkgrey rounded-full shadow-inner shadow-lightgrey mt-0.5 p-1"></div>
    );
  } else if (pts === 1) {
    probe = (
      <div className="flex w-7 h-7 rounded-full shadow-inner shadow-white bg-green mt-0.5 p-1"></div>
    );
  } else if (pts === 0) {
    probe = (
      <div className="flex w-7 h-7 rounded-full shadow-inner shadow-white bg-red mt-0.5 p-1"></div>
    );
  }
  return probe;
};

const Deadline = ({
  index,
  finalScore,
  value,
  pts,
  live,
  deadline,
  loading,
  justSaved,
  justError,
  gameNumber,
}) => {
  const topLabel = () => {
    if (finalScore) return finalScore;
    return live ? <Live /> : dayjs(deadline).locale("uk").format("HH:mm");
  };

  const isCalculated = finalScore && pts !== undefined;

  return (
    <div className="flex items-center justify-center mb-1 w-full">
      <div className="rounded text-black p-1 w-1/2 max-w-14 bg-lightgrey relative">
        {!isCalculated && (
          <div className="w-2.5 h-2.5 bg-primary absolute -top-1 left-3"></div>
        )}
        {!isCalculated && (
          <div className="w-2.5 h-2.5 bg-primary absolute -top-1 right-3"></div>
        )}
        {isCalculated ? (
          getResult(value, finalScore)
        ) : (
          <>
            <div className="relative text-xl top-0.5 text-primary">
              {dayjs(deadline).locale("uk").format("DD")}
            </div>
            <div className="relative text-xs top-0 font-bold">
              {dayjs(deadline).locale("uk").format("MMM")}
            </div>
          </>
        )}
      </div>
      <div className="text-sm font-bold relative left-1 flex flex-col items-center w-1/2 max-w-14 h-12.5">
        {topLabel()}
        {!loading && !justSaved && !justError && getProbe(pts)}
        {loading && (
          <CircularProgress className="bg-white !w-7 !h-7 !text-4xl p-1 mt-0.5 rounded-full !flex relative border border-solid border-white !text-primary" />
        )}
        {justSaved && (
          <CheckCircleSharp className="bg-white !w-7 !h-7 !text-4xl  p-1 mt-0.5 rounded-full !flex relative border border-solid border-white !text-green" />
        )}
        {justError && (
          <CancelRoundedIcon className="bg-white !w-7 !h-7 !text-4xl p-1 mt-0.5 rounded-full !flex relative border border-solid border-white !text-red" />
        )}
      </div>
    </div>
  );
};

export default Deadline;
