import { baseAuthUrl } from "../api";

const clientId =
  "VkwiriL9h1E6m53-1uPVKxoUf6KMkb_mHnele-8XU34EThUfR2T59Bd-Ytlcrr8Y";

export const goToLogin = () => {
  window.location.href = `${baseAuthUrl}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}/login`;
};

export const logout = () => {
  window.localStorage.clear();
  window.location.href = "/";
};

export const getFlagSource = (team) => {
  return `/flags/${team}.png`;
};

export const teamNames = {
  DYN: "ДИН",
  SHA: "ШАХ",
  OLE: "ОЛЕ",
  RUH: "РУХ",
  POL: "ПОЛ",
  MET: "МЕТ",
  ZOR: "ЗОР",
  VOR: "ВОР",
  LIB: "ЛІБ",
  MYN: "МИН",
  LNZ: "ЛНЗ",
  KRY: "КРИ",
  VER: "ВЕР",
  OBO: "ОБО",
  CHO: "ЧОР",
  KOL: "КОЛ",
  ING: "ІНГ",
  KAR: "КАР",
};

export const getTeamName = (teamId, teams, isMobile = false) => {
  if (!teamId) return;
  const targetTeam = teams.find((team) => team.TeamId === teamId);
  return isMobile ? teamNames[targetTeam?.TeamId] : targetTeam?.Name;
};

export const getPlayerNoString = (playerNo) => {
  if (!playerNo) return "";
  const digitsNumber = playerNo.toString().length;
  if (digitsNumber === 1) {
    return "00" + playerNo;
  } else if (digitsNumber === 2) {
    return "0" + playerNo;
  }

  return playerNo.toString();
};
