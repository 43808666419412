export const patreonUrl = "https://www.patreon.com/kdk_ua/membership";

export const primaryBlue = "#5d03b1";
export const primaryRed = "#101469";
export const primaryRedTransparent = "rgba(16, 24, 47, 0.7)";
export const gray = "gray";
export const green = "#4edd54";
export const red = "#f44336";
export const yellow = "rgb(255 222 1)";

export const teamColors = {
  DYN: ["#fff", "rgb(46,114,183)"],
  SHA: ["rgb(227,119,57)", "#000"],
  OLE: ["rgb(248,206,76)", "rgb(248,206,76)"],
  RUH: ["rgb(231,211,70)", "#000"],
  POL: ["rgb(253,238,80)", "rgb(77,169,101)"],
  MET: ["rgb(246,195,76)", "rgb(24,66,104)"],
  ZOR: ["#000", "#000"],
  VOR: ["rgb(74,163,90)", "rgb(74,163,90)"],
  LIB: ["rgb(226,202,78)", "rgb(43,85,54)"],
  MYN: ["rgb(165,204,230)", "rgb(165,204,230)"],
  LNZ: ["rgb(61,52,113)", "rgb(61,52,113)"],
  KRY: ["#fff", "rgb(236,73,65)"],
  VER: ["rgb(209,55,49)", "#000"],
  OBO: ["#fff", "rgb(44,85,53)"],
  CHO: ["rgb(42,79,157)", "rgb(42,79,157)"],
  KOL: ["#fff", "#000"],
  ING: ["rgb(248,210,72)", "rgb(182,55,48)"],
  KAR: ["rgb(74,196,90)", "rgb(74,196,90)"],
};
