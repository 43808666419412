import React, { useState } from "react";
import Page from "../components/Page";
import Grid from "@mui/material/Grid";
import { primaryBlue } from "../constants";
import { connect } from "react-redux";
import Head from "../components/Head";
import { Placeholder } from "../components/MyPredictions";
import { Table, TableCell, TableRow } from "@mui/material";
import Button from "../elements/Button";
import CalculateIcon from "@mui/icons-material/Calculate";
import API from "../api";
import { useNavigate } from "react-router-dom";
import { fetchApplicationData } from "../actions/init";

const styles = {
  section: {
    padding: 8,
    backgroundColor: primaryBlue,
  },
  input: {
    width: 30,
    height: 40,
    fontSize: "2rem",
    textAlign: "center",
    padding: 4,
    color: primaryBlue,
  },
  inputWrapper: {
    fontSize: "2rem",
    display: "flex",
    alignItems: "center",
    marginLeft: 8,
  },
  closeRoundBtn: {
    display: "flex",
    alignItems: "space-between",
    height: 80,
    justifyContent: "center",
  },
};

const Administration = ({
  matches,
  loaded,
  isAdmin,
  isCurrentGameClosed,
  fetchApplicationData,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const canCloseRound =
    !isCurrentGameClosed &&
    matches.filter((match) => !match.Calculated).length === 0;
  const canStartRound = Boolean(isCurrentGameClosed);

  const calculate = async () => {
    try {
      setLoading(true);
      await API.calculate();
      fetchApplicationData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const saveMatch = async (matchId) => {
    const firstScore = document.getElementById("firstScore-" + matchId)?.value;
    const secondScore = document.getElementById("secondScore-" + matchId)
      ?.value;

    if (firstScore && secondScore) {
      try {
        setLoading(true);
        await API.saveMatch({
          MatchId: matchId,
          FirstScore: Number(firstScore),
          SecondScore: Number(secondScore),
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const closeRound = async () => {
    try {
      setLoading(true);
      await API.closeRound();
      fetchApplicationData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const newRound = async () => {
    try {
      setLoading(true);
      await API.newRound();
      fetchApplicationData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (!isAdmin) {
    navigate("/");
  }

  return (
    <Page>
      <Grid item xs={12}>
        <div style={{ backgroundColor: primaryBlue }}>
          <Head
            text="Адміністрування"
            action={
              !loading ? (
                <CalculateIcon
                  style={{ fontSize: 32, cursor: "pointer" }}
                  onClick={calculate}
                  className="hovered"
                />
              ) : null
            }
          />
        </div>
        <div style={styles.section}>
          {loaded ? (
            <Table>
              {matches.map((match, index) => (
                <TableRow>
                  <TableCell align="left" style={{ minWidth: 200 }}>
                    <Grid
                      style={{ position: "relative" }}
                      container
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ width: 180 }}>
                        <span style={{ fontSize: "2rem" }}>
                          {match?.FirstTeam}
                        </span>
                        &nbsp;
                        <span style={{ fontSize: "2rem" }}>-</span>
                        &nbsp;
                        <span style={{ fontSize: "2rem" }}>
                          {match?.SecondTeam}
                        </span>
                      </div>
                      <div style={styles.inputWrapper}>
                        <input
                          defaultValue={match.FirstScore}
                          id={`firstScore-${match.MatchId}`}
                          style={styles.input}
                          type="text"
                        />
                        &nbsp;:&nbsp;
                        <input
                          defaultValue={match.SecondScore}
                          id={`secondScore-${match.MatchId}`}
                          type="text"
                          style={styles.input}
                        />
                      </div>
                    </Grid>
                  </TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      borderBottom: 0,
                    }}
                  >
                    <Button
                      disabled={loading}
                      onClick={() => saveMatch(match.MatchId)}
                      minWidth={0}
                    >
                      SAVE
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          ) : (
            <Placeholder meActive={true} loaded={loaded} />
          )}
        </div>
      </Grid>
      <div style={styles.closeRoundBtn}>
        {canCloseRound && <Button onClick={closeRound}>Закрити тур</Button>}
        {canStartRound && <Button onClick={newRound}>Новий тур</Button>}
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  matches: state.matches,
  loaded: state.app.loaded,
  isAdmin: state?.user?.me?.isAdmin,
  isCurrentGameClosed: state?.user?.isCurrentGameClosed,
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplicationData: (navigate) => dispatch(fetchApplicationData(navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
