import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const userIsLogged = localStorage.getItem("access_token");

  if (!userIsLogged) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default PrivateRoute;
