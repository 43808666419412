const userReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_USER':
            return action.payload
        case 'UPDATE_USER':
            return {
                ...state,
                me: {
                    ...state.me,
                    ...action.payload
                }
            }    
        default:
            return state
    }
}

export default userReducer
