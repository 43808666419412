import React from "react";

const Page = ({ children, classes }) => {
  return (
    <div
      className={`w-full max-w-screen-sm mx-auto pt-16 pb-0 bg-gradient-to-b from-primary to-primaryLight ${classes}`}
    >
      {children}
    </div>
  );
};

export default Page;
