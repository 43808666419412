import React, {useEffect} from 'react';
import Page from '../components/Page';
import Loader from '../components/Loader';
import { goToLogin } from '../utils';

const styles = {
    container: {
        height: 'calc(100vh - 65px)',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}


const Login = () => {
    useEffect(() => {
        const { search } = window.location
        if (!search && !localStorage.getItem('access-token')) {
           goToLogin(); //semechki
        }
        
    }, [])
    return (
        <Page>
            <div style={styles.container}>
                <Loader />
            </div>           
        </Page>
    )
}

export default Login;