import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({ players, onClickHamburger, drawer }) => {
  const [isIndexPage, setIndexPage] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIndexPage(
      Boolean(location.pathname === "/") ||
        Boolean(location.pathname === "/registration")
    );
  }, [location.pathname]);

  const goToHome = () => {
    if (location.pathname === "/registration") return;
    navigate("/home");
  };

  return (
    <header className="h-16 fixed w-full top-0 z-[9999]">
      <div className="h-full max-w-screen-sm mx-auto py-0 px-2 flex justify-between items-center bg-primary">
        <div
          className="flex items-center py-3 px-0 relative cursor-pointer"
          onClick={goToHome}
        >
          <div className="flex w-2.5 h-2.5 rounded-full top-8 left-1 bg-lightblue absolute"></div>
          <div className="flex w-2.5 h-2.5 rounded-full bottom-8 left-1 bg-yellow absolute"></div>
          <img src={"/fb-high-resolution-logo.png"} alt="" className="h-16" />
          <div className="relative uppercase font-bold text-2xl font-sans -ml-1 text-gold tracking-widest">
            ФУТБАТЛ
          </div>
        </div>

        {!isIndexPage &&
          (drawer ? (
            <CloseIcon
              className="border-solid scale-150 rounded-full p-2 cursor-pointer !text-4xl hover:text-gold"
              onClick={onClickHamburger}
            />
          ) : (
            <MenuIcon
              className="border-solid scale-150 rounded-full p-2 cursor-pointer !text-4xl hover:text-gold"
              onClick={onClickHamburger}
            />
          ))}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  players: state.players,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
