import API from "../api";

export const fetchTeams = () => async(dispatch, getState) => {
    const response = await API.fetchTeams();
    try {
        dispatch({
            type: 'FETCH_TEAMS',
            payload: response.data
        })
    } catch (error) {
        console.log('error', error);
    }

}
