const matchesReducer = (state = [], action) => {
    switch (action.type) {
      case 'FETCH_MATCHES':
        return action.payload
      default:
        return state
    }
  }
  
  export default matchesReducer
  