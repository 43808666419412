import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Deadline from "./Deadline";
import { savePrediction } from "../actions/predictions";
import { connect } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TeamLogo from "./TeamLogo";

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

const PredictionCard = ({
  firstTeam,
  firstTeamName,
  secondTeam,
  secondTeamName,
  deadline,
  match,
  firstScore,
  secondScore,
  initialScore,
  id,
  isActiveDeadline,
  savePrediction,
  index,
  live,
  pts,
  gameNumber,
  onSaveMatchId = () => undefined,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialScore);
  const [justSaved, setJustSaved] = useState(null);
  const [justError, setJustError] = useState(null);
  const [pristine, setPristine] = useState(true);

  // const ref = useRef(null);

  const handleChange = async (value) => {
    // if (pristine) return;
    const score = value.split("");
    const firstScorePartIsNumber = Number.isInteger(Number(score[0]));
    const secondScorePartIsNumber = Number.isInteger(Number(score[2]));

    if (
      score.length === 3 &&
      firstScorePartIsNumber &&
      secondScorePartIsNumber
    ) {
      setJustSaved(null);
      setJustError(null);
      setLoading(true);
      const response = await savePrediction(id, value, match.MatchId);
      if (response === 200) {
        onSaveMatchId(match.MatchId);

        setJustSaved(match.MatchId);
        setJustError(null);
      } else {
        setJustSaved(null);
        setJustError(match.MatchId);
      }
      setLoading(false);
    }
  };

  const sendScore = useMemo(
    () =>
      debounce((score) => {
        handleChange(score);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!pristine) sendScore(value);
  }, [value, pristine, sendScore]);

  const getFinalScore = () => {
    const isMatchCompleted =
      Number.isInteger(firstScore) && Number.isInteger(secondScore);
    return isMatchCompleted ? `${firstScore}:${secondScore}` : ``;
  };

  const handleFocus = () => {
    setPristine(false);
  };

  const handleFirstPlus = () => {
    if (typeof value !== "string") return;
    const score = value.split(":");

    if (Number(score[0]) === 9) return;

    const firstScore = score[0] === "" ? 0 : Number(score[0]) + 1;
    const secondScore = Number(score[1]);

    handleFocus();
    setValue(`${firstScore}:${secondScore}`);
  };

  const handleFirstMinus = () => {
    if (typeof value !== "string") return;
    const score = value.split(":");

    if (Number(score[0]) === 0) return;

    const firstScore = Number(score[0]) - 1;
    const secondScore = Number(score[1]);

    handleFocus();
    setValue(`${firstScore}:${secondScore}`);
  };

  const handleSecondPlus = () => {
    if (typeof value !== "string") return;
    const score = value.split(":");

    if (Number(score[1]) === 9) return;

    const firstScore = Number(score[0]);
    const secondScore = score[1] === "" ? 0 : Number(score[1]) + 1;

    handleFocus();
    setValue(`${firstScore}:${secondScore}`);
  };

  const handleSecondMinus = () => {
    if (typeof value !== "string") return;
    const score = value.split(":");

    if (Number(score[1]) === 0) return;

    const firstScore = Number(score[0]);
    const secondScore = Number(score[1]) - 1;

    handleFocus();
    setValue(`${firstScore}:${secondScore}`);
  };

  return (
    <Grid item xs={6} key={index}>
      <div className="pt-1">
        <div className="bg-gradient-to-b from-primary to-primaryLight h-16 flex flex-col justify-start items-center pb-1.5">
          <div className="flex text-center justify-start items-center uppercase text-lg text-white w-[90%]">
            <Deadline
              index={index}
              live={live}
              pts={pts}
              finalScore={getFinalScore()}
              value={value}
              deadline={deadline}
              loading={loading}
              justSaved={justSaved}
              justError={justError}
              gameNumber={gameNumber}
            />
          </div>
        </div>
        <div className="py-1 px-2 w-full bg-white rounded-b-md">
          <div className="flex flex-row items-center justify-between bg-white">
            <div className="flex items-center">
              <TeamLogo teamId={firstTeam} isFirstTeam />
              <div className="uppercase h-11 text-primary flex items-center font-bold">
                {firstTeamName}
              </div>
            </div>
            <div className="text-primary flex items-center text-2xl">
              {isActiveDeadline && (
                <ChevronLeftIcon
                  className="cursor-pointer hover:text-primary !text-3xl text-darkgrey"
                  onClick={handleFirstMinus}
                />
              )}
              <div
                className={`w-5 text-center ${
                  isActiveDeadline ? "p-0" : "px-8 py-0"
                }`}
              >
                {value?.split(":")[0] && value?.split(":")[0] !== "undefined"
                  ? value?.split(":")[0]
                  : "-"}
              </div>
              {isActiveDeadline && (
                <ChevronRightIcon
                  className="cursor-pointer hover:text-primary !text-3xl text-darkgrey"
                  onClick={handleFirstPlus}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between bg-white">
            <div className="flex items-center">
              <TeamLogo teamId={secondTeam} isFirstTeam />
              <div className="uppercase h-11 text-primary flex items-center font-bold">
                {secondTeamName}
              </div>
            </div>
            <div className="text-primary flex items-center text-2xl">
              {isActiveDeadline && (
                <ChevronLeftIcon
                  className="cursor-pointer hover:text-primary !text-3xl text-darkgrey"
                  onClick={handleSecondMinus}
                />
              )}
              <div
                className={`w-5 text-center ${
                  isActiveDeadline ? "p-0" : "px-8 py-0"
                }`}
              >
                {value?.split(":")[1] && value?.split(":")[1] !== "undefined"
                  ? value?.split(":")[1]
                  : "-"}
              </div>
              {isActiveDeadline && (
                <ChevronRightIcon
                  className="cursor-pointer hover:text-primary !text-3xl text-darkgrey"
                  onClick={handleSecondPlus}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  gameNumber: state.user.gameNumber,
  myPredictions: state.myPredictions,
});

const mapDispatchToProps = (dispatch) => ({
  savePrediction: (id, value, matchId) =>
    dispatch(savePrediction(id, value, matchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PredictionCard);
