import React from "react";
import Page from "../components/Page";
import { fetchDuels } from "../actions/predictions";
import { connect } from "react-redux";
import Navigation from "../components/Navigation";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import AdjustIcon from "@mui/icons-material/Adjust";
import StarIcon from "@mui/icons-material/Star";
import { getDisplayedName } from "./RoundDuels";

export const getShortPlayerName = (fullName = "") => {
  const chunks = fullName.split(" ");
  if (chunks.length > 1) {
    return chunks[0].at(0) + ". " + chunks[1];
  } else {
    return fullName;
  }
};

const Standings = ({ players = [], loaded, me }) => {
  const title = <>Турнірне становище</>;

  const navigate = useNavigate();

  const hasMe =
    players.filter((player) => player.UserId === me?.userId).length > 0;

  const scrollToMe = () => {
    const element = document.querySelector(`[data-user-id="${me?.userId}"]`);
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <Page classes="min-h-[100%]">
      <Navigation
        title={title}
        info={
          hasMe && (
            <div
              className="mr-4 flex items-center cursor-pointer"
              onClick={scrollToMe}
            >
              <AdjustIcon />
              &nbsp;Де я?
            </div>
          )
        }
      />
      {loaded ? (
        <div className="mt-10">
          <div className="fixed w-full max-w-[640px] uppercase flex justify-between items-center px-3 py-2 bg-gold text-primary font-bold">
            <div className="relative flex-[0_1_250px] left-11">Учасники</div>
            <div className="flex-[0_1_40px] text-center">І</div>
            <div className="hidden md:block">П</div>
            <div className="hidden md:block">Н</div>
            <div className="hidden md:block">П</div>
            <div className="flex-[0_1_55px] text-center">М</div>
            <div className="flex-[0_1_40px] text-center">О</div>
          </div>
          <div className="pt-10">
            {players.map((entry, index) => (
              <div
                data-user-id={entry.UserId}
                onClick={() => navigate(`/playerDuels/${entry.UserId}`)}
                className="group cursor-pointer uppercase flex justify-between items-center px-3 py-2 hover:bg-white hover:text-primary"
                key={entry.UserId}
              >
                <div className="flex-[0_1_250px] items-center flex">
                  <div className="bg-gold w-10 h-10 flex items-center justify-center mr-2 text-primary font-bold">
                    {entry.Rank}
                  </div>
                  <img
                    className="w-10 h-10 rounded-full mr-2 object-cover"
                    src={entry.Logo}
                    alt=""
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getDisplayedName(entry?.FullName),
                    }}
                  />
                  {entry.UserId === me?.userId && <StarIcon className="ml-1" />}
                </div>
                <div className="flex-[0_1_40px] text-center">
                  {entry.Games ?? "0"}
                </div>
                <div className="hidden md:block">{entry.W ?? "0"}</div>
                <div className="hidden md:block">{entry.D ?? "0"}</div>
                <div className="hidden md:block">{entry.L ?? "0"}</div>
                <div className="flex-[0_1_55px] text-center">
                  {entry.Z || "0-0"}
                </div>
                <div className="flex-[0_1_40px] font-bold text-center text-gold group-hover:text-primary">
                  {entry.Pts ?? "0"}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="h-[calc(100vh-64px)] bg-white flex items-center justify-center">
          <Loader />
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  loaded: state.app.loaded,
  players: state.players,
  me: state.user.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDuels: () => dispatch(fetchDuels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Standings);
