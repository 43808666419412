import React from "react";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";

const Head = ({
  icon = <> </>,
  text,
  action,
  loaded = true,
  onClick = undefined,
}) => {
  return (
    <Grid container spacing={1} marginTop={0} paddingLeft={1} paddingRight={1}>
      <Grid item paddingTop={0.5} xs={12}>
        {loaded ? (
          <h3
            className={`bg-primary font-bold m-0 pb-2 pt-0 px-0 text-gold uppercase flex items-center justify-between h-8.5 border-gold border-b border-solid ${
              onClick ? "cursor-pointer" : ""
            }`}
            onClick={onClick}
          >
            {icon && (
              <span className="text-white flex items-center text-base">
                {icon}&nbsp;
                {text}
              </span>
            )}
            {action}
          </h3>
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            style={{ width: "100%", backgroundColor: "#f3f3f3" }}
            height={35}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Head;
