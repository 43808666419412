import React from "react";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Navigation = ({ title, info }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Grid container spacing={0} marginTop={0}>
      <Grid item xs={12}>
        <h3 className="flex fixed bg-lightgrey m-0 p-2 text-primary uppercase items-center justify-between h-7 z-[99999] w-full max-w-[624px] box-content">
          <span className="flex items-center text-black">
            <ArrowBackIcon
              className="cursor-pointer hover:!text-primary"
              onClick={goBack}
            />
            &nbsp;{title}
          </span>
          <span>{info}</span>
        </h3>
      </Grid>
    </Grid>
  );
};

export default Navigation;
