import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { fetchDuels } from "../actions/predictions";
import { connect } from "react-redux";
import Navigation from "../components/Navigation";

import { primaryRed, yellow } from "../constants";
import { primaryBlue } from "../constants";
import Loader from "../components/Loader";
import DuelDialog from "../components/DuelDialog";
import API from "../api";
import { CircularProgress } from "@mui/material";

const styles = {
  card: {
    backgroundColor: primaryRed,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    padding: "0 16px",
  },
  head: {
    backgroundColor: "#f3f3f3",
    margin: "0",
    padding: 8,
    color: primaryBlue,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 25,
  },
  tableHead: {
    backgroundColor: primaryRed,
  },
  cellHead: {
    fontSize: "1rem",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "bold",
  },
  resultWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 40,
  },
  container: {
    height: "calc(100vh - 106px)",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  summary: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.8rem",
  },
  groupHead: {
    textTransform: "uppercase",
    padding: "16px 12px",
    margin: 0,
    color: yellow,
    borderTop: `1px solid #fff`,
  },
  duel: {
    textTransform: "uppercase",
    padding: "8px 12px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    color: primaryBlue,
  },
  standingsEntry: {
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 12px",
  },
};

const getPlayerInfoById = (players = [], id) => {
  return players.find((player) => player.UserId === id);
};

export const getShortPlayerName = (fullName = "") => {
  const chunks = fullName.split(" ");
  if (chunks.length > 1) {
    return chunks[0].at(0) + ". " + chunks[1];
  } else {
    return fullName;
  }
};

const getGroupByUserId = (infoItems = [], userId) => {
  let group = null;
  infoItems
    .filter((item) => !item.group?.length > 1)
    .forEach((item) => {
      item.standings.forEach((stItem) => {
        if (stItem.UserId === userId) {
          group = item.group;
        }
      });
    });
  return group;
};

const getGroupLabel = (title) => {
  switch (title) {
    case "A":
    case "B":
    case "C":
    case "D":
    case "E":
      return `Група ${title}`;
    case "1/4":
    case "1/2":
      return `${title} фіналу`;
    case "1/1":
      return "Фінал";
    default:
      return title;
  }
};

const Duel = ({ duels = [], teams = [], fetchDuels, loaded, me }) => {
  const [duelDialog, setDuelDialog] = useState(false);
  const [duelInfo, setDuelInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastDuelId, setLastDuelId] = useState(null);
  const [meGroup, setMeGroup] = useState(null);

  const openDuelDialog = () => setDuelDialog(true);
  const handleCloseDuelDialog = () => setDuelDialog(false);

  useEffect(() => {
    window.scrollToTopDisabled = true;
    fetchDuels();
  }, [fetchDuels]);

  useEffect(() => {
    const group = getGroupByUserId(duels, me?.userId);
    setMeGroup(group);
  }, [duels, me]);

  useEffect(() => {
    if (!duels.length) return;
    const yOffset = -100;
    const element = document.querySelector(`[data-id="${meGroup}"]`);
    if (element) {
      const y =
        element?.getBoundingClientRect()?.top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [duels, meGroup]);

  const title = <>Розклад/турнірне становище</>;

  const fetchDuel = async (duelId) => {
    try {
      setLoading(true);
      setLastDuelId(duelId);
      const response = await API.fetchDuel(duelId);
      setDuelInfo(response.data);
      openDuelDialog();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page>
      <Navigation title={title} />
      {loaded ? (
        <div style={{ marginTop: 40 }}>
          {duels.map((row) => (
            <>
              <h3 data-id={row.group} style={styles.groupHead}>
                {getGroupLabel(row.group)}
              </h3>
              {row.duels.map((duel) => (
                <div key={duel.DuelId} style={styles.duel}>
                  <div
                    data-id={duel.FirstPlayer}
                    style={{ flex: "0 1 195px", textAlign: "left" }}
                  >
                    {getShortPlayerName(
                      getPlayerInfoById(row.standings, duel.FirstPlayer)
                        ?.FullName
                    )}
                  </div>
                  <div
                    className="hover"
                    style={{
                      flex: "0 1 56px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      fetchDuel(duel.DuelId);
                    }}
                  >
                    {loading && duel.DuelId === lastDuelId ? (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    ) : (
                      <>
                        {duel.FirstPlayerScore}-{duel.SecondPlayerScore}
                      </>
                    )}
                  </div>
                  <div
                    data-id={duel.SecondPlayer}
                    style={{ flex: "0 1 195px", textAlign: "right" }}
                  >
                    {getShortPlayerName(
                      getPlayerInfoById(row.standings, duel.SecondPlayer)
                        ?.FullName
                    )}
                  </div>
                </div>
              ))}
              {row.group?.length === 1 &&
                row.standings.map((entry) => (
                  <div style={styles.standingsEntry}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "0 1 195px",
                      }}
                    >
                      <img
                        src={entry.Logo}
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "100%",
                          marginRight: 8,
                          objectFit: "cover",
                        }}
                      />
                      {getShortPlayerName(entry.FullName)}
                    </div>
                    <div>{entry.Games ?? "0"}</div>
                    <div>{entry.W ?? "0"}</div>
                    <div>{entry.D ?? "0"}</div>
                    <div>{entry.L ?? "0"}</div>
                    <div>{entry.Z || "0-0"}</div>
                    <div style={{ color: yellow }}>{entry.Pts ?? "0"}</div>
                  </div>
                ))}
            </>
          ))}
        </div>
      ) : (
        <div style={styles.container}>
          <Loader />{" "}
        </div>
      )}
      <DuelDialog
        open={duelDialog}
        handleClose={handleCloseDuelDialog}
        duelInfo={duelInfo}
        teams={teams}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  duels: state.duel,
  loaded: state.app.loaded,
  teams: state.teams,
  me: state.user.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDuels: () => dispatch(fetchDuels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Duel);
