import Axios from "axios";
import { logout } from "../utils";
import { patreonUrl } from "../constants";

const interceptor = (store) => {
  Axios.interceptors.request.use(
    (conf) => {
      store.dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  Axios.interceptors.response.use(
    (next) => {
      store.dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      return Promise.resolve(next);
    },
    (error) => {
      const status = error.response && error.response.status;

      store.dispatch({
        type: "SET_LOADING",
        payload: false,
      });

      if (status > 499) {
        store.dispatch({
          type: "SHOW_ERROR",
          payload: error.message,
        });

        setTimeout(() => {
          store.dispatch({
            type: "SHOW_ERROR",
            payload: "",
          });
        }, 5000);
      }

      if (status === 401) {
        logout();
      }

      if (status === 403) {
        window.location.href = patreonUrl;
      }

      return Promise.reject(error);
    }
  );
};
export default interceptor;
