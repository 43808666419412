import React from "react";

const Button = ({
  children,
  onClick,
  disabled,
  fullWidth,
  height,
  minWidth,
  fontSize,
  icon,
}) => {
  return (
    <button
      className="hover:text-white hover:bg-primary text-primary bg-white pt-2 pr-4 pb-2 pl-1 leading-10 flex items-center justify-center uppercase shadow-2xl shadow-white font-bold cursor-pointer overflow-hidden border border-lightgrey"
      style={{
        minWidth: minWidth ?? 165,
        fontSize: fontSize ?? "1rem",
        height: height ?? 44,
        width: fullWidth ? "100%" : "auto",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}&nbsp;
      {children}
    </button>
  );
};

export default Button;
