import React from "react";
import Button from "../elements/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getShortPlayerName } from "../pages/Duel";
import { CheckCircle, Error } from "@mui/icons-material";
import TeamLogo from "./TeamLogo";
import { getTeamName } from "../utils";
import CloseIcon from "@mui/icons-material/Close";

const getPenaltiesScore = (roundMatches = []) => {
  const calculatedAdditionalMatches = roundMatches.filter(
    (match) => match.IsAdditional && match.Calculated
  );
  const firstPlayerAdditionalPts = calculatedAdditionalMatches.reduce(
    (acc, curr) => acc + curr.firstPlayerPrediction.Pts,
    0
  );
  const secondPlayerAdditionalPts = calculatedAdditionalMatches.reduce(
    (acc, curr) => acc + curr.secondPlayerPrediction.Pts,
    0
  );

  return `${firstPlayerAdditionalPts}:${secondPlayerAdditionalPts}`;
};

const isAdditionalVisible = (roundMatches = []) => {
  const calculatedMainMatches = roundMatches.filter(
    (match) => !match.IsAdditional && match.Calculated
  );
  const firstPlayerMainPts = calculatedMainMatches.reduce(
    (acc, curr) => acc + (curr.firstPlayerPrediction?.Pts || 0),
    0
  );
  const secondPlayerMainPts = calculatedMainMatches.reduce(
    (acc, curr) => acc + (curr.secondPlayerPrediction?.Pts || 0),
    0
  );

  const isAllMatchesCalculated =
    calculatedMainMatches.length ===
    roundMatches.filter((match) => !match.IsAdditional).length;

  return isAllMatchesCalculated && firstPlayerMainPts === secondPlayerMainPts;
};

const getPlayerPrediction = (matchPrediction, matchPtsClass) => {
  if (matchPrediction) {
    return (
      <>
        {matchPrediction.FirstScore}:{matchPrediction.SecondScore}
        <span
          className={`absolute -top-2.5 text-sm font-bold text-green ${matchPtsClass}`}
        >
          {matchPrediction.Pts ? `+${matchPrediction.Pts}` : ``}
        </span>
      </>
    );
  } else {
    return "-";
  }
};

export default function DuelDialog({ open, handleClose, duelInfo, teams }) {
  return (
    <Dialog
      className="!font-sans"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle
        id="responsive-dialog-title"
        className="!font-sans flex text-base font-bold items-center text-primary uppercase"
      >
        <div className="flex items-center justify-between w-full md:min-w-[460px]">
          <div className="flex justify-start items-center flex-[0_1_200px] relative text-base">
            <img
              src={duelInfo?.duel?.FirstPlayer?.Logo}
              alt=""
              className="h-8 w-8 rounded-full mr-2 object-cover"
            />
            <div className="absolute -bottom-3 -left-2">
              {duelInfo?.duel?.isFirstPlayerReady ? (
                <CheckCircle className="text-green !text-base" />
              ) : (
                <Error className="text-red !text-base" />
              )}
            </div>
            <span className="hidden md:block text-sm font-bold">
              {getShortPlayerName(duelInfo?.duel?.FirstPlayer?.FullName)}
            </span>
          </div>
          <div className="text-center text-2xl font-bold min-w-9">
            {duelInfo?.duel?.FirstPlayerScore}:
            {duelInfo?.duel?.SecondPlayerScore}
          </div>
          <div className="flex justify-end items-center flex-[0_1_200px] relative">
            <span className="hidden md:block mr-2 text-sm font-bold">
              {getShortPlayerName(duelInfo?.duel?.SecondPlayer?.FullName)}
            </span>
            <img
              src={duelInfo?.duel?.SecondPlayer?.Logo}
              alt=""
              className="h-8 w-8 rounded-full object-cover"
            />
            <div className="absolute -bottom-3 -right-2">
              {duelInfo?.duel?.isSecondPlayerReady ? (
                <CheckCircle className="text-green !text-base" />
              ) : (
                <Error className="text-red !text-base" />
              )}
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text-black !font-sans">
          {duelInfo?.roundMatches
            ?.filter((match) => !match.IsAdditional)
            .map((match) => (
              <div
                key={match.MatchId}
                className="flex items-center justify-around px-0 py-2 text-black"
              >
                <div className="justify-start w-8 flex items-center flex-[0_1_90px] relative">
                  {getPlayerPrediction(match.firstPlayerPrediction, "-left-4")}
                </div>
                <div className="flex justify-center items-center flex-[0_1_80px]">
                  <TeamLogo teamId={match.FirstTeam} isFirstTeam />
                  <span className="w-8">
                    {getTeamName(match.FirstTeam, teams, true)}
                  </span>
                </div>
                <div className="text-center font-bold w-24">
                  {match.FirstScore}:{match.SecondScore}
                </div>
                <div className="flex justify-center items-center flex-[0_1_80px]">
                  <span className="w-8">
                    {getTeamName(match.SecondTeam, teams, true)}
                  </span>
                  <TeamLogo teamId={match.SecondTeam} />
                </div>
                <div className="justify-end w-8 flex items-center flex-[0_1_90px] relative">
                  {getPlayerPrediction(
                    match.secondPlayerPrediction,
                    "-right-4"
                  )}
                </div>
              </div>
            ))}
          {isAdditionalVisible(duelInfo?.roundMatches) ? (
            <div className="hidden">
              <hr />
              {duelInfo?.roundMatches
                ?.filter((match) => match.IsAdditional)
                .map((match) => (
                  <div
                    key={match.MatchId}
                    className="flex items-center justify-around px-0 py-2"
                  >
                    <div className="justify-start w-8 flex items-center flex-[0_1_90px] relative">
                      {getPlayerPrediction(
                        match.firstPlayerPrediction,
                        "-left-4"
                      )}
                    </div>
                    <div className="flex justify-center items-center flex-[0_1_80px]">
                      <img
                        src={`/flags/${match.FirstTeam}.png`}
                        alt=""
                        className="h-8 w-8 rounded-full mr-2 object-cover"
                      />
                      <span className="hidden md:block">{match.FirstTeam}</span>
                    </div>
                    <div className="text-center font-bold w-24">
                      {match.FirstScore}-{match.SecondScore}
                    </div>
                    <div className="flex justify-center items-center flex-[0_1_80px]">
                      <span className="md:block hidden">
                        {match.SecondTeam}
                      </span>

                      <img
                        src={`/flags/${match.SecondTeam}.png`}
                        alt=""
                        className="h-8 w-8 rounded-full mr-2 object-cover"
                      />
                    </div>
                    <div className="justify-end w-8 flex items-center flex-[0_1_90px] relative">
                      {getPlayerPrediction(
                        match.secondPlayerPrediction,
                        "-right-4"
                      )}
                    </div>
                  </div>
                ))}
              <div className="text-center font-bold mt-2 text-primary">
                {getPenaltiesScore(duelInfo?.roundMatches)}
              </div>
            </div>
          ) : (
            <></>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="bg-primary !justify-center !items-center">
        <Button variant="outlined" onClick={handleClose}>
          <CloseIcon />
          &nbsp;Закрити
        </Button>
      </DialogActions>
    </Dialog>
  );
}
