import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { fetchApplicationData } from "./actions/init";
import PrivateRoute from "./elements/PrivateRoute";

import Header from "./components/Header";
import Home from "./pages/Home";
import Players from "./pages/Players";
import Login from "./pages/Login";
import Landing from "./pages/Landing";
import Registration from "./pages/Registration";
import FirstGame from "./pages/FirstGame";

import { useNavigate, useLocation } from "react-router-dom";
import OneSignal from "react-onesignal";
import SecondGame from "./pages/SecondGame";
import ThirdGame from "./pages/ThirdGame";
import PlayerDetails from "./pages/PlayerDetails";
import ScrollToTop from "./elements/ScrollToTop";
import Administration from "./pages/Administration";
import Duel from "./pages/Duel";
import MobileAuth from "./pages/MobileAuth";
import Drawer from "@mui/material/Drawer";
import DrawerContent from "./components/DrawerContent";
import PlayerDuels from "./pages/PlayerDuels";
import Standings from "./pages/Standings";
import RoundDuels from "./pages/RoundDuels";

function App({ fetchApplicationData }) {
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = useCallback(() => {
    fetchApplicationData(navigate);
  }, [fetchApplicationData, navigate]);

  useEffect(() => {
    OneSignal.init({
      appId: "b0b886a9-36f1-4dc6-bfc4-d0d28148c179",
    });
    if (location.pathname !== "/mobile-auth") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHamburger = () => {
    setDrawer((prevDrawer) => !prevDrawer);
  };

  return (
    <div className="App h-full">
      <ScrollToTop />
      <Header drawer={drawer} onClickHamburger={onClickHamburger} />
      <Routes>
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          exact
          path="/players"
          element={
            <PrivateRoute>
              <Players />
            </PrivateRoute>
          }
        />
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/registration" element={<Registration />} />
        <Route exact path="/game/1" element={<FirstGame />} />
        <Route exact path="/game/2" element={<SecondGame />} />
        <Route exact path="/game/3" element={<ThirdGame />} />
        <Route
          path="/players/:userId/:playerNo/:hearts"
          element={
            <PrivateRoute>
              <PlayerDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/duels"
          element={
            <PrivateRoute>
              <Duel />
            </PrivateRoute>
          }
        />
        <Route
          path="/playerDuels/:userId"
          element={
            <PrivateRoute>
              <PlayerDuels />
            </PrivateRoute>
          }
        />
        <Route
          path="/roundDuels/:round"
          element={
            <PrivateRoute>
              <RoundDuels />
            </PrivateRoute>
          }
        />
        <Route
          path="/standings"
          element={
            <PrivateRoute>
              <Standings />
            </PrivateRoute>
          }
        />
        <Route path="/administration" element={<Administration />} />
        <Route path="/mobile-auth" element={<MobileAuth />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <React.Fragment>
        <Drawer anchor="bottom" open={drawer} onClose={() => setDrawer(false)}>
          <DrawerContent onClose={() => setDrawer(false)} />
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchApplicationData: (navigate) => dispatch(fetchApplicationData(navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
