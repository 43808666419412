import { combineReducers } from 'redux'
import teamsReducer from './teamsReducer'
import playersReducer from './playersReducer'
import matchesReducer from './matchesReducer'
import myPredictionsReducer from './myPredictionsReducer'
import playerPredictionsReducer from './playerPredictionsReducer'
import appReducer from './appReducer'
import userReducer from './userReducer'
import duelReducer from './duelReducer'

export default combineReducers({
  teams: teamsReducer,
  players: playersReducer,
  matches: matchesReducer,
  myPredictions: myPredictionsReducer,
  playerPredictions: playerPredictionsReducer,
  user: userReducer,
  duel: duelReducer,
  app: appReducer,
})
