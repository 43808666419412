import React from "react";
import Page from "../components/Page";
import Navigation from "../components/Navigation";
import Grid from "@mui/material/Grid";
import { primaryRed, primaryBlue } from "../constants";
import FavoriteIcon from "@mui/icons-material/Favorite";

const styles = {
  card: {
    backgroundColor: primaryRed,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  dollImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    position: "relative",
    top: 0,
    display: "flex",
  },
  bar: {
    backgroundColor: "#fff",
    padding: 16,
  },
  head: {
    margin: 0,
    color: primaryRed,
    textTransform: "uppercase",
  },
  paragraph: {
    color: primaryBlue,
  },
  panel: {
    position: "absolute",
    left: 0,
    top: 24,
    height: "100%",
    width: "100%",
  },
  gamePanel: {
    position: "absolute",
    backgroundColor: primaryRed,
    bottom: 0,
    padding: "0 16px",
    textTransform: "uppercase",
  },
  hint: {
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    marginLeft: 8,
    marginBottom: 8,
  },
  imgContainer: {
    position: "relative",
    marginTop: 41,
  },
  traficLights: {
    position: "absolute",
    height: 100,
    right: 12,
    top: 12,
  },
  hearts: {
    color: primaryRed,
    fontSize: "1rem",
    position: "relative",
    top: 2,
  },
};

const ThirdGame = () => {
  return (
    <Page>
      <Navigation title="Правила гри" />
      <Grid item xs={12}>
        <div style={styles.card}>
          <div style={styles.imgContainer}>
            <img src="/bridge.png" alt="Суперфінал" style={styles.dollImage} />
            <div style={styles.gamePanel}>
              <h3 style={styles.question}>Гра 3</h3>
            </div>
          </div>

          <div style={styles.bar}>
            <h3 style={styles.head}>Суперфінал</h3>
            {true && (
              <>
                <p style={styles.paragraph}>
                  "Суперфінал" - це гра, яка розпочнеться зі стартом полуфіналів
                  ЛЧ та, ймовірно, триватиме аж до фіналу у Стамбулі. В цій грі
                  братимуть участь всі ті гравці, які залишилися у грі після
                  перших двох ігор.
                </p>
                <p style={styles.paragraph}>
                  Всі учасники суперфіналу будуть поділені за набраними
                  сердечками на 2 кошики:
                  <ul>
                    <li>
                      <strong>Перший кошик</strong>: учасники, які мають на
                      балансі <strong>5 та більше</strong> сердечок
                    </li>
                    <li>
                      <strong>Другий кошик</strong>: учасники, які мають на
                      балансі <strong>4 та менше</strong> сердечок
                    </li>
                  </ul>
                  Після розподілу на кошики відбудеться перерахунок сердечок
                  учасників за наступним принципом:
                  <ul>
                    <li>
                      Баланс сердечок гравців <strong>з першого кошику</strong>{" "}
                      становитиме 3 <FavoriteIcon style={styles.hearts} />{" "}
                    </li>
                    <li>
                      Баланс сердечок гравців <strong>з другого кошику</strong>{" "}
                      становитиме 2 <FavoriteIcon style={styles.hearts} />{" "}
                    </li>
                  </ul>
                  Ну а далі, власне, проведення суперфіналу{" "}
                  <strong>за правилами Гри №1</strong>. Гра буде зупинена
                  достроково як тільки у грі залишиться один гравець або не
                  залишиться гравців зовсім.
                </p>
                <p style={styles.paragraph}>
                  <strong>P.S.</strong> Якщо раптом трапиться так, що за
                  результатами суперфіналу ми не отримаємо одного переможця,
                  тоді найкращий гравець визначатиметься{" "}
                  <strong>за спортивним принципом</strong>.
                  <br />
                  <br />В цьому випадку переможець{" "}
                  <strong>"Шляху до Стамбулу"</strong> визначиться за сумою
                  балів набраною за увесь плей-офф Ліги чемпіонів.
                  <br />
                  <br />
                  Спортивні бали будуть обчислені за формулою:
                  <br />
                  <br />
                  <strong>
                    (Кількість вгаданих рахунків * 5) + (Кількість вгаданих
                    результатів * 2)
                  </strong>
                </p>
              </>
            )}
          </div>
        </div>
      </Grid>
    </Page>
  );
};

export default ThirdGame;
