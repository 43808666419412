import React from 'react';
import Grid from '@mui/material/Grid';
import Page from '../components/Page';
import PlayerCard from '../components/PlayerCard';
import { connect } from 'react-redux';
import { getPlayerNoString } from '../utils'
import Navigation from '../components/Navigation'
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { red } from '../constants';
import CustomAnimatedNumber from '../components/CustomAnimatedNumber'
 
const styles = {
    container: {
        padding: '0px 8px 8px 0px',
        backgroundColor: '#fff',
        margin: '41px 0 0 0',
        width: '100%'
    },
    loaderContainer: {
        height: 'calc(100vh - 106px)',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    indicator: {
        position: 'absolute',
        right: 24,
        bottom: 8
    }
}

const Players = ({ players = [], loaded, gameNumber }) => {
    const navigate = useNavigate();

    const goToPlayerDetails = (userId, playerNo, hearts) => {
        if (gameNumber === 1 || gameNumber === 3) {
            navigate(`/players/${userId}/${playerNo}/${hearts}`)
        } else {
            navigate(`/duels/${userId}`)
        }
    }
    
    const allPlayers = players?.length;
    const activePlayers = players.filter(player => player.Active)?.length;

    const indicator = <div style={styles.indicator}><span style={{color: '#369a3a'}}><CustomAnimatedNumber value={activePlayers} /></span>/<span style={{color: red}}>{allPlayers}</span></div>
    
    return (
            <Page>
                <Navigation title={`Гравці`} info={indicator} />
                { loaded ? <Grid container spacing={1} marginTop={0.25} style={styles.container}> 
                    {players.map(player => 
                        <Grid item xs={3} key={player.UserId}>
                            <PlayerCard
                                onClick = {() => goToPlayerDetails(player.UserId, player.PlayerNo, player.Lifes)}
                                logoUrl={player.Logo}
                                playerNo={getPlayerNoString(player.PlayerNo)}
                                hearts={player.Lifes}
                                active={Boolean(player.Active)}
                            />
                        </Grid> )
                    }
                </Grid> : <div style={styles.loaderContainer}><Loader /></div>
                }
            </Page>
    )
}


const mapStateToProps = (state) => ({
    players: state.players,
    loaded: state.app.loaded,
    gameNumber: state.user.gameNumber
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Players);