import API from '../api'

export const fetchMatches = () => async (dispatch, getState) => {
  const response = await API.fetchMatches()
  const sortedMatches = response.data.items.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime())

  try {
    dispatch({
      type: 'FETCH_MATCHES',
      payload: sortedMatches,
    })
  } catch (error) {
    console.log('error', error)
  }
}
