import React from 'react';
import Page from '../components/Page';
import Navigation from '../components/Navigation';
import Grid from '@mui/material/Grid';
import { primaryRed, primaryBlue } from '../constants';

const styles = {
    card: {
        backgroundColor: primaryRed,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    dollImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top',
        position: 'relative',
        top: 0,
        display: 'flex'
    },
    bar: {
        backgroundColor: '#fff',
        padding: 16,
    },
    head: {
        margin: 0,
        color: primaryRed,
        textTransform: 'uppercase'
    },
    paragraph: {
        color: primaryBlue,
    },
    panel: {
        position: 'absolute',
        left: 0,
        top: 24,
        height: '100%',
        width: '100%',
    },
    gamePanel: {
        position: 'absolute',
        backgroundColor: primaryRed,
        bottom: 0,
        padding: '0 16px',
        textTransform: 'uppercase'
    },
    hint: {
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        marginLeft: 8,
        marginBottom: 8
    },
    imgContainer: {
        position: 'relative',
        marginTop: 41
    },
    traficLights: {
        position: 'absolute',
        height: 100,
        right: 12,
        top: 12
    },
    hearts: {        
        color: primaryRed,
        fontSize: '1rem',
        position: 'relative',
        top: 2
    }
}

const SecondGame = () => {

    return (
        <Page>
            <Navigation title="Правила гри" />
            <Grid item xs={12}>
                <div style={styles.card}>
                    <div style={styles.imgContainer}> 
                        <img src="/marbles.png" alt="Кольорові кульки" style={styles.dollImage} />
                        <div style={styles.gamePanel}>
                            <h3 style={styles.question}>Гра 2</h3>
                        </div>
                    </div>
                    <div style={styles.bar}>
                        <h3 style={styles.head}>Дуелі</h3>
                        <p style={styles.paragraph}>
                            "Дуелі" - це гра, яка розпочнеться зі стартом 1/4 фіналу Ліги чемпіонів і травитиме 8 матчів.
                        </p>
                        <p style={styles.paragraph}>
                            Всі учасники, які залишилися у грі за допомогою "рандому" будуть розбиті на пари та прогнозуватимуть рахунки <strong>основного часу</strong> футбольних матчів стадії плей-офф.
                        </p>
                        <p style={styles.paragraph}>
                            <ul>
                                <li>За точно вгаданий рахунок - гравець отримає <strong>+5 балів</strong></li>
                                <li>За вірно вгаданий результат (перемога, нічия, поразка) <strong>+2 бали</strong></li>
                            </ul>
                        </p>
                        <p style={styles.paragraph}>
                            <strong>Мета гри:</strong> набрати більше балів ніж суперник. Учасник, який набере у парі більше балів, виходить у фінал.
                            Гравець, який набере меншу кільксіть балів гру залишає.
                        </p>
                        <p style={styles.paragraph}>
                            У разі, якщо обидва гравці в парі зберуть однакову кількість кульок, переможцем буде оголошено учасника, який набрав більше сердечок у Грі №1.
                        </p>
                        <p style={styles.paragraph}>
                            Якщо обидва гравці у парі не здобудуть жодної кульки - обидва гравці залишають гру. 
                        </p>
                    </div>
                </div>
            </Grid>
        </Page>
    )
}

export default SecondGame;