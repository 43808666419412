import React from "react";
import { teamColors } from "../constants/index";

const TeamLogo = ({ teamId = "", isFirstTeam = false }) => {
  const teamColor = teamColors[teamId];
  const isRealLogo = teamColor.length === 0;

  return isRealLogo ? (
    <img
      src={`/flags/${teamId}.png`}
      alt=""
      className="h-8 w-8 rounded-full object-contain "
      style={{
        marginRight: isFirstTeam ? 8 : 0,
        marginLeft: !isFirstTeam ? 8 : 0,
      }}
    />
  ) : (
    <div
      className="w-8 h-8 flex flex-wrap rounded-full"
      style={{
        marginRight: isFirstTeam ? 8 : 0,
        marginLeft: !isFirstTeam ? 8 : 0,
        background: `radial-gradient(${teamColor[0]}, ${teamColor[1]})`,
      }}
    ></div>
  );
};

export default TeamLogo;
