const myPredictionsReducer = (state = [], action) => {
  switch (action.type) {
    case "INIT_MY_PREDICTIONS":
    case "FETCH_MY_PREDICTIONS":
      return action.payload;
    case "UPDATE_PREDICT":
      const { firstScore, secondScore } = action.payload;
      return state.map((item) =>
        item.MatchId === action.payload.matchId
          ? {
              ...item,
              FirstScore: Number(firstScore),
              SecondScore: Number(secondScore),
            }
          : item
      );
    default:
      return state;
  }
};

export default myPredictionsReducer;
