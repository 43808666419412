import API from "../api";
import { v4 as uuidv4 } from "uuid";

const hasPrediction = (matchId, myPredictions) => {
  return myPredictions.filter((pred) => pred.MatchId === matchId).length > 0;
};

const getPrediction = (matchId, myPredictions) => {
  return myPredictions.find((pred) => pred.MatchId === matchId);
};

export const fetchMyPredictions = () => async (dispatch, getState) => {
  try {
    const response = await API.fetchPredictions();
    dispatch({
      type: "FETCH_MY_PREDICTIONS",
      payload: response.data,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const fetchPublicPredictions = (user) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADED",
      payload: false,
    });
    const response = await API.fetchPublicPredictions(user);

    dispatch({
      type: "FETCH_USER_PUBLIC_PREDICTIONS",
      payload: response.data,
    });
  } catch (error) {
    console.log("error", error);
  } finally {
    dispatch({
      type: "SET_LOADED",
      payload: true,
    });
  }
};

export const fetchDuels = ({ userId, round }) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADED",
      payload: false,
    });

    const response = await API.fetchDuels({ userId, round });
    dispatch({
      type: "FETCH_DUEL",
      payload: response.data,
    });
  } catch (error) {
    console.log("error", error);
  } finally {
    dispatch({
      type: "SET_LOADED",
      payload: true,
    });
  }
};

export const buildMyPredictions = () => async (dispatch, getState) => {
  const matches = getState().matches;
  const userId = getState().user?.me?.userId;
  const myPredictions = getState().myPredictions;

  const predictions = matches.map((match) =>
    hasPrediction(match.MatchId, myPredictions)
      ? getPrediction(match.MatchId, myPredictions)
      : {
          MatchId: match.MatchId,
          user: userId,
          FirstScore: "",
          SecondScore: "",
          PredictionId: uuidv4(),
        }
  );

  dispatch({
    type: "INIT_MY_PREDICTIONS",
    payload: predictions,
  });
};

export const savePrediction = (id, value, matchId) => async (
  dispatch,
  getState
) => {
  const scoreChunks = value.split(":");
  const [firstScore, secondScore] = scoreChunks;

  if (
    (isNaN(firstScore) && !isNaN(secondScore)) ||
    (isNaN(secondScore) && !isNaN(firstScore))
  )
    return;
  try {
    const res = await API.savePrediction(matchId, firstScore, secondScore, id);
    dispatch({
      type: "UPDATE_PREDICT",
      payload: {
        firstScore,
        secondScore,
        matchId,
      },
    });
    return res.status;
  } catch (err) {
    console.log(err);
  }
};
