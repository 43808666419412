const appReducer = (state = { loaded: false }, action) => {
    switch (action.type) {
      case 'SHOW_ERROR':
        return { ...state, error: action.payload }
      case 'SET_LOADING':
        return { ...state, loading: action.payload }
      case 'SET_LOADED':
        return { ...state, loaded: action.payload }
      case 'SET_ADMIN':
        return { ...state, isAdmin: action.payload }
      default:
        return state
    }
  }
  
  export default appReducer
  