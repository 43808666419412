import React, { useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { getPlayerInfoById } from "../pages/PlayerDuels";
import DuelDialog from "./DuelDialog";
import API from "../api";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { getDisplayedName } from "../pages/RoundDuels";
import { useNavigate } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const MyDuel = ({ duel, players, hasDuel, teams = [] }) => {
  const [loading, setLoading] = useState(false);
  const [duelDialog, setDuelDialog] = useState(false);
  const [duelInfo, setDuelInfo] = useState(false);

  const openDuelDialog = () => setDuelDialog(true);
  const handleCloseDuelDialog = () => setDuelDialog(false);

  const navigate = useNavigate();

  const firstPlayerInfo = useMemo(
    () => getPlayerInfoById(players, duel?.FirstPlayer),
    [players, duel]
  );

  const secondPlayerInfo = useMemo(
    () => getPlayerInfoById(players, duel?.SecondPlayer),
    [players, duel]
  );

  const firstPlayer = useMemo(
    () => getDisplayedName(firstPlayerInfo?.FullName),
    [firstPlayerInfo]
  );

  const secondPlayer = useMemo(
    () => getDisplayedName(secondPlayerInfo?.FullName),
    [secondPlayerInfo]
  );

  const fetchDuel = async (duelId) => {
    try {
      setLoading(true);
      const response = await API.fetchDuel(duelId);
      setDuelInfo(response.data);
      openDuelDialog();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return hasDuel ? (
    <div className="flex items-center justify-between w-full text-base">
      <div
        className="flex relative items-center text-base flex-initial w-48 cursor-pointer"
        onClick={() => navigate(`/playerDuels/${duel.FirstPlayer}`)}
      >
        <span className="flex items-center justify-center absolute h-4 w-4 -bottom-1 left-0 text-xs rounded-full bg-white text-primary">
          {firstPlayerInfo?.Rank}
        </span>
        <img
          src={getPlayerInfoById(players, duel?.FirstPlayer)?.Logo}
          className="h-8 w-8 rounded-full mr-2 object-cover"
          alt=""
        />
        <div
          dangerouslySetInnerHTML={{
            __html: firstPlayer,
          }}
        />
      </div>
      <div
        className={`hover:text-white hover:bg-red ${
          loading ? "bg-red" : "bg-gold"
        } text-primary cursor-pointer h-8 text-xl font-bold flex flex-initial w-14 text-center items-center justify-center`}
        onClick={() => {
          fetchDuel(duel.DuelId);
        }}
      >
        {loading ? (
          <CircularProgress size={16} className="!text-yellow" />
        ) : (
          <>
            {duel.FirstPlayerScore}:{duel.SecondPlayerScore}
          </>
        )}
      </div>
      <div
        className="flex relative items-center text-base flex-initial w-48 justify-end cursor-pointer"
        onClick={() => navigate(`/playerDuels/${duel.SecondPlayer}`)}
      >
        <div
          className="text-right mr-2"
          dangerouslySetInnerHTML={{
            __html: secondPlayer,
          }}
        />
        <span className="flex items-center justify-center absolute h-4 w-4 -bottom-1 right-0 text-xs rounded-full bg-white text-primary">
          {secondPlayerInfo?.Rank}
        </span>
        <img
          src={getPlayerInfoById(players, duel?.SecondPlayer)?.Logo}
          className="h-8 w-8 rounded-full object-cover"
          alt=""
        />
      </div>

      <DuelDialog
        open={duelDialog}
        handleClose={handleCloseDuelDialog}
        duelInfo={duelInfo}
        teams={teams}
      />
    </div>
  ) : (
    <div className="flex items-center justify-between w-full">
      <BootstrapTooltip
        style={{ zIndex: 99999999999 }}
        title={
          <div className="text-sm p-1 w-64">
            <p className="mb-2">
              Ваш суперник буде визначений після старту першого футбольного
              матчу туру.{" "}
            </p>
            <p className="mb-2">
              Зробіть прогнози на тур, дочекайтесь жеребкування та виграйте батл
              у свого суперника.
            </p>
          </div>
        }
        placement="bottom"
      >
        <div className="flex items-center justify-center cursor-pointer">
          Суперник не визначений&nbsp;
          <HelpIcon />
        </div>
      </BootstrapTooltip>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.user.me,
  players: state.players,
  teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyDuel);
