import React from "react";
import { connect } from "react-redux";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { logout } from "../utils";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import SyncIcon from "@mui/icons-material/Sync";
import { fetchApplicationData } from "../actions/init";
import PeopleIcon from "@mui/icons-material/People";
import { ReactComponent as PatreonIcon } from "../patreon.svg";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CustomAnimatedNumber from "./CustomAnimatedNumber";

const getIcon = (playersNumber, index) => {
  switch (index) {
    case 0:
      return <CheckIcon className="!text-5xl !text-gold" />;
    case 1:
      return (
        <div className="relative">
          <PeopleIcon className="!text-5xl !text-gold" />
          <div className="flex items-center justify-center w-7 h-7 absolute bg-red text-white rounded-full -bottom-1 right-2 p-1">
            <CustomAnimatedNumber value={playersNumber} />
          </div>
        </div>
      );
    case 2:
      return <TelegramIcon className="!text-5xl !text-gold" />;
    default:
      break;
  }
};

export const getMultilineName = (fullName = "") => {
  return fullName.split(" ").join("<br/>");
};

const DrawerContent = ({ user, onClose, fetchApplicationData, players }) => {
  const navigate = useNavigate();
  const goToFbGroup = (e) => {
    window.open("https://www.facebook.com/groups/footbattle", "_blank");
  };

  const goToInsta = () => {
    window.open("https://www.instagram.com/footbattle.click", "_blank");
  };

  const goToTelega = () => {
    window.open("https://t.me/+r7cW6SfXBpJlYjIy", "_blank");
  };

  const goToPatreon = () => {
    window.open("https://www.patreon.com/footbattle", "_blank");
  };

  return (
    <div className="bg-purple h-64">
      <div className="flex items-center justify-between text-white px-2 pt-2 max-w-[628px] mx-auto h-16">
        <div className="flex items-center">
          <img
            className="flex w-12 h-12 rounded-full"
            src={user?.me?.logo}
            alt=""
          />
          <div
            className="font-bold ml-2 uppercase text-white"
            dangerouslySetInnerHTML={{
              __html: getMultilineName(user?.me?.fullName),
            }}
          />
        </div>
        <div>
          {user?.me?.isAdmin && (
            <SettingsApplicationsIcon
              className="!text-5xl mr-2 hover:text-gold cursor-pointer"
              onClick={() => {
                onClose();
                navigate("/administration");
              }}
            />
          )}

          <SyncIcon
            className="!text-5xl mr-2 hover:text-gold cursor-pointer"
            onClick={() => {
              onClose();
              fetchApplicationData(navigate);
            }}
          />
          <ExitToAppIcon
            className="!text-5xl hover:text-gold cursor-pointer"
            onClick={logout}
          />
        </div>
      </div>

      <div className="flex flex-row items-center p-2 max-w-[628px] mx-auto">
        {["МОЇ БАТЛИ", "УЧАСНИКИ", "ТЕЛЕГРАМ"].map((item, index) => (
          <div
            key={item}
            className="flex items-center justify-center cursor-pointer w-1/3 bg-primary border border-primary h-28 hover:border hover:border-gold"
            style={{ marginRight: index === 2 ? 0 : 8 }}
            onClick={() => {
              onClose();
              index === 0 && navigate(`/playerDuels/${user?.me?.userId}`);
              index === 1 && navigate("/standings");
              index === 2 && goToTelega();
            }}
          >
            <div className="flex flex-col items-center justify-center">
              {getIcon(players.length, index)}
              <div className="mt-2 text-white">{item}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row items-center uppercase text-white px-2 py-0 max-w-[628px] mx-auto">
        <div className="flex w-full items-center justify-around bg-primary p-1">
          <InstagramIcon
            onClick={goToInsta}
            className="mr-2.5 !text-5xl cursor-pointer hover:text-gold"
          />
          <FacebookIcon
            onClick={goToFbGroup}
            className="mr-2.5 !text-5xl cursor-pointer hover:text-gold"
          />
          <PatreonIcon
            className="mr-2.5 w-8 fill-white cursor-pointer hover:fill-gold"
            onClick={goToPatreon}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  players: state.players,
});

const mapDispatchToProps = (dispatch) => ({
  fetchApplicationData: (navigate) => dispatch(fetchApplicationData(navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
