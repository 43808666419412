import React, { useEffect } from "react";
import Page from "../components/Page";
import { primaryRed } from "../constants";
import Navigation from "../components/Navigation";
import { primaryBlue } from "../constants";
import { getFlagSource, getPlayerNoString, getTeamName } from "../utils";
import { connect } from "react-redux";
import { fetchPublicPredictions } from "../actions/predictions";
import {
  Grid,
  Avatar,
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

const styles = {
  card: {
    backgroundColor: primaryRed,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    padding: "0 16px",
  },
  head: {
    backgroundColor: "#f3f3f3",
    margin: "0",
    padding: 8,
    color: primaryBlue,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 25,
  },
  tableHead: {
    backgroundColor: primaryRed,
  },
  cellHead: {
    fontSize: 10,
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "bold",
  },
  resultWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 40,
  },
  container: {
    height: "calc(100vh - 106px)",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  summary: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.8rem",
  },
};

const isPredictionSent = (prediction) => {
  const { FirstPredictionScore, SecondPredictionScore } = prediction;
  return (
    FirstPredictionScore !== undefined && SecondPredictionScore !== undefined
  );
};

const getPredictionResult = (prediction) => {
  const { Pts } = prediction;
  if (!isPredictionSent(prediction)) return `-1`;
  if (Pts > 0) return `+${Pts}`;
  if (Pts < 0) return `${Pts}`;
  return "";
};

const getBgColor = (prediction) => {
  const { Pts } = prediction;
  if (!isPredictionSent(prediction)) return "rgb(255 0 0 / 30%)";
  if (Pts === undefined) return "#fff";
  if (Pts > 0) return "rgb(64 230 17 / 30%)";
  if (Pts < 0) return "rgb(255 0 0 / 30%)";
  return "rgb(255 235 59 / 30%)";
};

const getPlayerPrediction = (prediction) => {
  const { FirstPredictionScore, SecondPredictionScore } = prediction;
  if (!isPredictionSent(prediction)) return "-";
  return `${FirstPredictionScore}:${SecondPredictionScore}`;
};

const isMatchCompleted = (prediction) =>
  prediction.FirstScore !== undefined && prediction.SecondScore !== undefined;

const PlayerDetails = ({
  playerPredictions = [],
  fetchPublicPredictions,
  loaded,
  teams,
}) => {
  const params = useParams();
  const { userId, playerNo, hearts } = params;

  useEffect(() => {
    window.scrollToTopDisabled = true;
    fetchPublicPredictions(userId);
  }, [fetchPublicPredictions, userId]);

  return (
    <Page>
      <Navigation title={`Гравець ${getPlayerNoString(playerNo)}`} />
      {loaded ? (
        <TableContainer
          style={{ borderRadius: 0, marginTop: 41 }}
          component={Paper}
        >
          <Table>
            <TableHead style={styles.tableHead}>
              <TableCell component="th" scope="row" style={styles.cellHead}>
                Ставка
              </TableCell>
              <TableCell style={styles.cellHead}>Матч</TableCell>
              <TableCell style={styles.cellHead}>
                <div style={styles.summary}>
                  {hearts}&nbsp;
                  <FavoriteIcon />
                </div>
              </TableCell>
            </TableHead>
            <TableBody>
              {playerPredictions.map((prediction) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ backgroundColor: getBgColor(prediction) }}
                >
                  <TableCell component="tr" scope="row">
                    <strong>{getPlayerPrediction(prediction)}</strong>
                  </TableCell>
                  <TableCell align="left">
                    <Grid
                      style={{ position: "relative" }}
                      container
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Avatar
                        imgProps={{ style: { objectFit: "contain" } }}
                        src={getFlagSource(prediction.FirstTeam)}
                        sx={{ width: 36, height: 36, marginRight: 0.5 }}
                      />
                      <span
                        style={{
                          width: 90,
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        {getTeamName(prediction.FirstTeam, teams)?.slice(0, 3)}{" "}
                        -{" "}
                        {getTeamName(prediction.SecondTeam, teams)?.slice(0, 3)}
                      </span>
                      <Avatar
                        imgProps={{ style: { objectFit: "contain" } }}
                        src={getFlagSource(prediction.SecondTeam)}
                        sx={{ width: 36, height: 36, marginLeft: 0.5 }}
                      />
                      &nbsp;
                      {isMatchCompleted(prediction) && (
                        <span>
                          {prediction.FirstScore}:{prediction.SecondScore}
                        </span>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell align="right">
                    {isMatchCompleted(prediction) && (
                      <div style={styles.resultWrapper}>
                        {getPredictionResult(prediction)}&nbsp;
                        <FavoriteIcon style={{ color: primaryRed }} />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={styles.container}>
          <Loader />{" "}
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  playerPredictions: state.playerPredictions,
  loaded: state.app.loaded,
  teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPublicPredictions: (userId) => dispatch(fetchPublicPredictions(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDetails);
