import { fetchTeams } from "./teams";
import { fetchUserInfo } from "./user";
import { fetchMatches } from "./matches";
import { fetchMyPredictions, buildMyPredictions } from "./predictions";
import API from "../api";

const getUserInfo = (navigate) => async (dispatch) => {
  const { search } = window.location;

  const params = new URLSearchParams(search);
  const code = params.get("code");

  if (!code && !localStorage.getItem("access_token")) return;

  const userInfo = await dispatch(fetchUserInfo(code));

  await dispatch({
    type: "FETCH_USER",
    payload: userInfo,
  });

  if (userInfo.access_token) {
    localStorage.setItem("access_token", userInfo.access_token);
  }

  if (userInfo.refresh_token) {
    localStorage.setItem("refresh_token", userInfo.refresh_token);
  }

  const isRegistered = userInfo?.me?.Registered;

  if (code) {
    if (isRegistered) {
      navigate("/home");
    } else {
      navigate("/registration");
    }
  }
};

const resolveAdminRights = () => async (dispatch, getState) => {
  const admin = Boolean(getState().user?.me?.isAdmin);
  dispatch({
    type: "SET_ADMIN",
    payload: admin,
  });
};

export const fetchPlayers = () => async (dispatch, getState) => {
  try {
    const response = await API.fetchPlayers();
    dispatch({ type: "FETCH_PLAYERS", payload: response.data.items });
  } catch (error) {
    console.log(error);
  }
};

export const fetchApplicationData = (navigate) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: "SET_LOADED",
    payload: false,
  });
  await dispatch(getUserInfo(navigate));

  const isLoggedIn = Boolean(localStorage.getItem("access_token"));

  await dispatch(resolveAdminRights());

  if (isLoggedIn) {
    await Promise.all([
      dispatch(fetchPlayers()),
      dispatch(fetchTeams()),
      dispatch(fetchMatches()),
      dispatch(fetchMyPredictions()),
    ]);
    await dispatch(buildMyPredictions());
  }

  dispatch({
    type: "SET_LOADED",
    payload: true,
  });
};
