import React from "react";
import Head from "./Head";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlayerInfoById } from "../pages/PlayerDuels";
import CustomAnimatedNumber from "./CustomAnimatedNumber";

const UserInfo = ({ user, loaded, players }) => {
  const navigate = useNavigate();
  const playerInfo = getPlayerInfoById(players, user?.me?.userId);

  const getIcon = () => {
    return (
      <div className="relative flex items-center">
        <div className="h-7.5 min-w-3 px-2 py-0 bg-gold flex items-center justify-center mr-1.5 text-primary">
          {playerInfo?.Rank}
        </div>
      </div>
    );
  };

  return (
    <Head
      loaded={loaded}
      icon={getIcon()}
      text={user?.me?.fullName}
      onClick={() => navigate(`/playerDuels/${user?.me?.userId}`)}
      action={
        <div className="mr-2.5">
          <CustomAnimatedNumber value={playerInfo?.Pts} />
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  players: state.players,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
