import API from '../api'

export const fetchUserInfo = (authCode) => async (dispatch) => {
  try {
    const response = await API.fetchUserInfo(authCode)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const register = (body) => async(dispatch) => {
    try {
        const response = await API.register(body);
        dispatch({
            type: 'UPDATE_USER',
            payload: response.data.Attributes
        })
        return response.data
      } catch (error) {
        console.log(error)
      }
}
